import { Suspense, useRef } from "react";
import { motion } from "framer-motion-3d";
import { useThree, useFrame, Canvas } from "@react-three/fiber";
import { SoftShadows, useGLTF, useHelper, useTexture } from "@react-three/drei";
import { useMotionValue, useTime, useTransform } from "framer-motion";
import {
  ACESFilmicToneMapping,
  BackSide,
  MeshPhysicalMaterial,
  RepeatWrapping,
  SRGBColorSpace,
} from "three";
//import { useHelper } from "@react-three/drei/core/useHelper";
//import { DirectionalLightHelper } from "three";

function polyInOut(t, e) {
  return ((t *= 2) <= 1 ? Math.pow(t, e) : 2 - Math.pow(2 - t, e)) / 2;
}

export function SceneSpin() {
  const time = useTime();
  const isRotating = useMotionValue(1);
  const rotationY = useTransform(
    [time, isRotating],
    ([t, isRotating]) =>
      //isRotating * Math.PI * Math.sin(0.001 * t) - Math.PI / 2
      isRotating * 2 * Math.PI * polyInOut((0.0002 * t) % 1, 4) + Math.PI / 2
  );

  return (
    <Suspense fallback={<div>Loading</div>}>
      <Canvas
        shadows
        gl={{
          antialias: true,
          toneMapping: ACESFilmicToneMapping,
          toneMappingExposure: 1.5,
        }}
        style={{ width: "100vw", height: "100dvh", backgroundColor: "#000" }}
      >
        <color attach="background" args={["#000"]} />
        <Lights />
        <FloorPlank
          position={[0, -0.5, 0]}
          rotation={[0, rotationY, Math.PI / 2]}
          scale={1}
          visible={true}
        />
        <mesh position={[0, 6, 0]} scale={20} receiveShadow>
          <boxGeometry />
          <meshPhysicalMaterial color="#999" roughness={0.7} side={BackSide} />
        </mesh>
        <SoftShadows size={150} focus={5} samples={47} />
      </Canvas>
    </Suspense>
  );
}

function Lights({ lightColor }) {
  const three = useThree();
  const [spotlightMap] = useTexture(["/textures/spotlight-map.png"]);

  useFrame(() => three.camera.lookAt(0, 0, 0));
  const light1 = useRef();
  //useHelper(light1, SpotLightHelper);
  const light2 = useRef();
  //useHelper(light2, SpotLightHelper);
  const light3 = useRef();
  //useHelper(light3, DirectionalLightHelper);

  return (
    <>
      {/*<fog attach="fog" args={["#ff5020", 5, 18]} />
          <Sky scale={1000} sunPosition={[2, 0.4, 10]} />*/}
      <hemisphereLight
        color={"#eaf0ff"}
        groundColor={"#000000"}
        intensity={0}
      />
      <motion.ambientLight intensity={0} color={"#fff"} />

      <group position={[0.5, 5, -0.5]} rotation={[0, 0, 0]}>
        <motion.spotLight
          intensity={1}
          angle={0.32}
          penumbra={1}
          decay={0.8}
          castShadow
          map={spotlightMap}
          shadow-bias={-0.005}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-focus={1}
          ref={light1}
        />
      </group>

      <group position={[-0.5, 5, 0.5]} rotation={[0, 0, 0]}>
        <motion.spotLight
          intensity={1}
          angle={0.32}
          penumbra={1}
          decay={0.8}
          castShadow
          map={spotlightMap}
          shadow-bias={-0.005}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-focus={1}
          ref={light2}
        />
      </group>

      <group position={[4, 0, 3]} rotation={[0, 0, 0]}>
        <motion.spotLight
          intensity={2}
          angle={0.7}
          penumbra={1}
          decay={0.8}
          castShadow
          map={spotlightMap}
          shadow-bias={-0.005}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-focus={1}
          ref={light3}
        />
      </group>

      <motion.directionalLight
        intensity={0}
        position={[-0.5, 3, -1]}
        color="white"
      />
      <motion.directionalLight
        intensity={0}
        position={[2, 0, 1.5]}
        color="white"
      />
    </>
  );
}

function FloorPlank({ ...rest }) {
  const { nodes, materials } = useGLTF("/gltf/5g-dry.glb");
  console.log(nodes, materials);

  const [colorMap, roughnessMap, normalMap] = useTexture([
    "/floors/30085/color.jpg",
    "/floors/30085/roughness.jpg",
    "/floors/30085/normal.jpg",
  ]);

  const repeatX = 0.4;
  const repeatY = 0.875;
  const floorMaterial = new MeshPhysicalMaterial();
  floorMaterial.clearcoat = 0.1;
  floorMaterial.clearcoatRoughness = 0.55;
  floorMaterial.reflectivity = 0.3;
  floorMaterial.metalness = 0;
  floorMaterial.map = colorMap;
  floorMaterial.map.encoding = SRGBColorSpace;
  floorMaterial.map.wrapS = floorMaterial.map.wrapT = RepeatWrapping;
  floorMaterial.map.repeat.set(repeatX, repeatY);
  floorMaterial.roughnessMap = roughnessMap;
  floorMaterial.roughnessMap.wrapS = floorMaterial.roughnessMap.wrapT =
    RepeatWrapping;
  floorMaterial.roughnessMap.repeat.set(repeatX, repeatY);
  floorMaterial.normalMap = normalMap;
  floorMaterial.normalMap.wrapS = floorMaterial.normalMap.wrapT =
    RepeatWrapping;
  floorMaterial.normalMap.repeat.set(repeatX, repeatY);

  const baseMaterial = new MeshPhysicalMaterial();
  baseMaterial.color.set("#cbb287");
  baseMaterial.color.encoding = SRGBColorSpace;
  baseMaterial.roughness = 0.9;
  baseMaterial.reflectivity = 0.1;
  baseMaterial.metalness = 0;

  return (
    <motion.group {...rest} dispose={null}>
      <group position={[1.5, 0, -0.5]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001.geometry}
          material={floorMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001_1.geometry}
          material={baseMaterial}
        />
      </group>
    </motion.group>
  );
}

useGLTF.preload("/gltf/5g-dry.glb");
