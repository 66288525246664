import { Suspense, useMemo, useRef } from "react";
import { motion } from "framer-motion-3d";
import { useThree, useFrame, Canvas } from "@react-three/fiber";
import {
  SoftShadows,
  Environment,
  useHelper,
  useTexture,
  Lightformer,
} from "@react-three/drei";
import { useMotionValue, useTime, useTransform } from "framer-motion";
import {
  ACESFilmicToneMapping,
  BackSide,
  CanvasTexture,
  MeshPhysicalMaterial,
  RepeatWrapping,
  SRGBColorSpace,
} from "three";
import { randFloatSpread } from "three/src/math/MathUtils.js";
//import { useHelper } from "@react-three/drei/core/useHelper";
//import { DirectionalLightHelper } from "three";

function polyInOut(t, e) {
  return ((t *= 2) <= 1 ? Math.pow(t, e) : 2 - Math.pow(2 - t, e)) / 2;
}

export function SceneLayers() {
  const time = useTime();
  const isAnimating = useMotionValue(1);
  const rotationY = useTransform(
    [time, isAnimating],
    ([t, isAnimating]) =>
      //isAnimating * Math.PI * Math.sin(0.001 * t) - Math.PI / 2
      isAnimating * 2 * Math.PI * polyInOut((0.0002 * t) % 1, 4) + Math.PI / 2
  );
  const posY = useTransform(
    [time, isAnimating],
    ([t, isAnimating]) => isAnimating * Math.sin(0.001 * t)
  );
  const spread = useTransform(
    [time, isAnimating],
    ([t, isAnimating]) => 1 + isAnimating * Math.sin(0.001 * t)
  );
  const posY1 = useTransform([posY], ([posY]) => posY + 1.04);
  const posY2 = useTransform([posY], ([posY]) => 0.5 * posY + 0.52);
  const posY3 = useTransform([posY], ([posY]) => -0.5 * posY - 0.51);
  const posY4 = useTransform([posY], ([posY]) => -posY - 1.04);

  const windowX = [0, 1.1, 2.3, 3.4];
  const windowY = [0, 1.1, 2.2, 3.3, 4.4];

  return (
    <Suspense fallback={<div>Loading</div>}>
      <Canvas
        shadows
        gl={{
          antialias: true,
          toneMapping: ACESFilmicToneMapping,
          toneMappingExposure: 1.5,
        }}
        style={{ width: "100vw", height: "100dvh", backgroundColor: "#000" }}
      >
        <Environment frames={1} resolution={256} blur={0.5} background>
          <Lightformers />
        </Environment>
        <color attach="background" args={["#333"]} />
        <Lights />
        <CoatingLayer
          position={[0, posY1, 0]}
          rotation={[0, rotationY, Math.PI / 2]}
          scale={1}
          visible={true}
        />
        <WoodLayer
          thickness={0.01}
          position={[0, posY2, 0]}
          rotation={[0, rotationY, Math.PI / 2]}
          scale={1}
          visible={true}
        />
        <PowderLayer
          spread={spread}
          position={[0, posY3, 0]}
          rotation={[0, rotationY, Math.PI / 2]}
          scale={1}
          visible={true}
        />
        <WoodLayer
          position={[0, posY4, 0]}
          rotation={[0, rotationY, Math.PI / 2]}
          scale={1}
          visible={true}
        />
        <SoftShadows size={150} focus={5} samples={47} />
      </Canvas>
    </Suspense>
  );
}

function Lights({ lightColor }) {
  const three = useThree();
  const [spotlightMap] = useTexture(["/textures/spotlight-map.png"]);

  useFrame(() => three.camera.lookAt(0, 0, 0));
  const light1 = useRef();
  //useHelper(light1, SpotLightHelper);
  const light2 = useRef();
  //useHelper(light2, SpotLightHelper);
  const light3 = useRef();
  //useHelper(light3, DirectionalLightHelper);

  return (
    <>
      <ambientLight color={"#ffffff"} intensity={0.1} />
      <group position={[-0.5, 10, 0.5]} rotation={[0, 0, 0]}>
        <motion.spotLight
          intensity={1}
          angle={0.5}
          penumbra={1}
          decay={0.8}
          castShadow
          map={spotlightMap}
          shadow-bias={0}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-focus={1}
          ref={light2}
        />
      </group>

      <group position={[8, 8, 5]} rotation={[0, 0, 0]}>
        <motion.spotLight
          intensity={1}
          angle={0.7}
          penumbra={1}
          decay={0.8}
          castShadow
          map={spotlightMap}
          shadow-bias={0}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-focus={1}
          ref={light3}
        />
      </group>
    </>
  );
}

function Lightformers({ positions = [2, 0, 2, 0, 2, 0, 2, 0] }) {
  const group = useRef();
  return (
    <>
      {/* Ceiling */}
      <Lightformer
        intensity={0.25}
        rotation-x={Math.PI / 2}
        position={[0, 5, -9]}
        scale={[10, 10, 1]}
      />
      <group rotation={[0, 0.5, 0]}>
        <group ref={group}>
          {positions.map((x, i) => (
            <Lightformer
              key={i}
              form="circle"
              intensity={1}
              rotation={[Math.PI / 2, 0, 0]}
              position={[x, 4, i * 4]}
              scale={[3, 1, 1]}
            />
          ))}
        </group>
      </group>
      {/* Sides */}
      <Lightformer
        intensity={1}
        rotation-y={Math.PI / 2}
        position={[-5, 1, -1]}
        scale={[20, 0.1, 1]}
      />
      <Lightformer
        rotation-y={Math.PI / 2}
        position={[-5, -1, -1]}
        scale={[20, 0.5, 1]}
      />
      <Lightformer
        rotation-y={-Math.PI / 2}
        position={[10, 1, 0]}
        scale={[20, 1, 1]}
      />
    </>
  );
}

function WoodLayer({ thickness = 0.05, ...rest }) {
  const [colorMap, roughnessMap, normalMap] = useTexture([
    "/floors/30085/color.jpg",
    "/floors/30085/roughness.jpg",
    "/floors/30085/normal.jpg",
  ]);

  const repeatX = 0.4;
  const repeatY = 0.875;
  const floorMaterial = new MeshPhysicalMaterial();
  floorMaterial.clearcoat = 0;
  floorMaterial.reflectivity = 0.3;
  floorMaterial.metalness = 0;
  floorMaterial.map = colorMap;
  floorMaterial.map.encoding = SRGBColorSpace;
  floorMaterial.map.wrapS = floorMaterial.map.wrapT = RepeatWrapping;
  floorMaterial.map.repeat.set(repeatX, repeatY);
  floorMaterial.roughnessMap = roughnessMap;
  floorMaterial.roughnessMap.wrapS = floorMaterial.roughnessMap.wrapT =
    RepeatWrapping;
  floorMaterial.roughnessMap.repeat.set(repeatX, repeatY);
  floorMaterial.normalMap = normalMap;
  floorMaterial.normalMap.wrapS = floorMaterial.normalMap.wrapT =
    RepeatWrapping;
  floorMaterial.normalMap.repeat.set(repeatX, repeatY);

  return (
    <motion.group {...rest} dispose={null}>
      <mesh scale={1} castShadow receiveShadow material={floorMaterial}>
        <boxGeometry args={[thickness, 3, 3]} />
      </mesh>
    </motion.group>
  );
}

function CoatingLayer({ ...rest }) {
  return (
    <motion.group {...rest} dispose={null}>
      <mesh scale={1} castShadow receiveShadow>
        <boxGeometry args={[0.02, 3, 3]} />
        <meshPhysicalMaterial
          color="#fff"
          emissive="#fff"
          emissiveIntensity={0.05}
          ior={2}
          roughness={0.1}
          specularColor={"#fff"}
          specularIntensity={2}
          transparent
          transmission={1}
          opacity={1}
        />
      </mesh>
    </motion.group>
  );
}

var canvas = document.createElement("CANVAS");
canvas.width = 128;
canvas.height = 128;

var context = canvas.getContext("2d");
context.globalAlpha = 0.3;
context.filter = "blur(16px)";
context.fillStyle = "white";
context.beginPath();
context.arc(64, 64, 40, 0, 2 * Math.PI);
context.fill();
context.globalAlpha = 1;
context.filter = "blur(5px)";
context.fillStyle = "white";
context.beginPath();
context.arc(64, 64, 16, 0, 2 * Math.PI);
context.fill();

var pointTexture = new CanvasTexture(canvas);

function PowderLayer({ spread, ...rest }) {
  const THREE = useThree();

  const count = 5000;

  // This reference gives us direct access to our points
  const points = useRef();

  // Generate our positions attributes array
  const particlesPosition = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const distance = 1;

    for (let i = 0; i < count; i++) {
      let x = 0;
      let y = randFloatSpread(distance * 2);
      let z = randFloatSpread(distance * 2);

      positions.set([x, y, z], i * 3);
    }

    return positions;
  }, [count]);

  const particlesPositionInital = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const distance = 1.5;

    for (let i = 0; i < count; i++) {
      let x = 0;
      let y = randFloatSpread(distance * 2);
      let z = randFloatSpread(distance * 2);

      positions.set([x, y, z], i * 3);
    }

    return positions;
  }, [count]);

  useFrame((state) => {
    for (let i = 0; i < count; i++) {
      const i3 = i * 3;

      points.current.geometry.attributes.position.array[i3] =
        Math.abs(i3) * spread.get() * 0.00005;
      points.current.geometry.attributes.position.array[i3 + 1] =
        particlesPositionInital[i3 + 1] *
        (1 + spread.get() * 0.2 * Math.abs(particlesPositionInital[i3 + 1]));
      points.current.geometry.attributes.position.array[i3 + 2] =
        particlesPositionInital[i3 + 2] *
        (1 + spread.get() * 0.2 * Math.abs(particlesPositionInital[i3 + 2]));
    }

    points.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <motion.group {...rest} dispose={null}>
      <points ref={points}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particlesPosition.length / 3}
            array={particlesPosition}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial
          size={0.07}
          color="#000"
          sizeAttenuation
          depthWrite={false}
          map={pointTexture}
          transparent
        />
      </points>
    </motion.group>
  );
}
