import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { Switch } from "react-router";
import "./index.css";
import ScrollContainer from "./ScrollContainer";
import ErrorDisplay from "./ErrorDisplay";
import { SceneSpin } from "./SceneSpin";
import { SceneLayers } from "./SceneLayers";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const Menu = () => (
  <div style={{ padding: "2em" }}>
    <h1>3D demos</h1>
    <ul>
      <li>
        <Link to="/spin">Spin</Link>
      </li>
      <li>
        <Link to="/layers">Layers</Link>
      </li>
      <li>
        <Link to="/scroll">Scroll</Link>
      </li>
    </ul>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary boundaryId="root" fallback={<ErrorDisplay />}>
      <Suspense fallback={null}>
        <Router>
          <Switch>
            <Route key={"spin"} path={"/spin"} component={SceneSpin} />
            <Route key={"layers"} path={"/layers"} component={SceneLayers} />
            <Route
              key={"scroll"}
              path={"/scroll"}
              component={ScrollContainer}
            />
            <Route key={"spin"} path={"*"} component={Menu} />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>
);
