import React from "react";

// Originally forked from https://codesandbox.io/s/r3f-basic-demo-3izwu?file=/src/App.js:0-2775
export default function SectionDummy() {
  return (
    <div
      className="scroll-snap-section"
      style={{ backgroundColor: "#cccccc", height: "100dvh", padding: "5vmin" }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Dignissim sodales ut
      eu sem integer vitae. Facilisis gravida neque convallis a cras semper.
      Massa placerat duis ultricies lacus sed turpis. Risus nullam eget felis
      eget nunc lobortis mattis. Amet justo donec enim diam vulputate ut
      pharetra. Malesuada bibendum arcu vitae elementum curabitur vitae nunc.
      Tellus rutrum tellus pellentesque eu tincidunt tortor aliquam nulla.
      Euismod lacinia at quis risus. Lectus proin nibh nisl condimentum id
      venenatis a condimentum vitae. Commodo ullamcorper a lacus vestibulum sed
      arcu. Sit amet consectetur adipiscing elit ut. Mattis vulputate enim nulla
      aliquet. Morbi quis commodo odio aenean sed adipiscing diam donec. Non
      diam phasellus vestibulum lorem sed. Nisl rhoncus mattis rhoncus urna
      neque viverra justo. Mollis aliquam ut porttitor leo a diam. Vitae congue
      eu consequat ac felis donec et. Nunc non blandit massa enim nec dui nunc.
      Consequat ac felis donec et odio pellentesque diam volutpat commodo. Amet
      volutpat consequat mauris nunc congue nisi. At elementum eu facilisis sed
      odio morbi quis commodo. Sit amet mattis vulputate enim. Sem nulla
      pharetra diam sit. Id leo in vitae turpis massa sed elementum. Elementum
      integer enim neque volutpat. Mauris nunc congue nisi vitae suscipit tellus
      mauris. Cursus turpis massa tincidunt dui. Feugiat pretium nibh ipsum
      consequat nisl vel pretium lectus quam. Enim ut sem viverra aliquet eget
      sit amet. Eros in cursus turpis massa. Ultrices sagittis orci a
      scelerisque purus semper. Dolor sit amet consectetur adipiscing elit ut
      aliquam. Felis bibendum ut tristique et egestas quis ipsum suspendisse.
      Accumsan in nisl nisi scelerisque eu ultrices vitae auctor. Etiam sit amet
      nisl purus in mollis nunc sed. Viverra aliquet eget sit amet tellus cras
      adipiscing. Adipiscing at in tellus integer feugiat scelerisque varius
      morbi. Ut sem viverra aliquet eget sit amet tellus. Montes nascetur
      ridiculus mus mauris vitae ultricies leo. Amet nisl suscipit adipiscing
      bibendum est ultricies integer quis auctor. Urna duis convallis convallis
      tellus id. Rhoncus est pellentesque elit ullamcorper dignissim cras
      tincidunt lobortis feugiat. Amet consectetur adipiscing elit pellentesque
      habitant morbi tristique senectus. Consequat ac felis donec et odio
      pellentesque diam volutpat. Nulla facilisi cras fermentum odio eu. Ipsum a
      arcu cursus vitae congue mauris rhoncus. Venenatis tellus in metus
      vulputate eu. Turpis in eu mi bibendum neque egestas. In vitae turpis
      massa sed. Ornare lectus sit amet est placerat in egestas erat.
      Consectetur libero id faucibus nisl tincidunt. In tellus integer feugiat
      scelerisque varius morbi enim nunc faucibus. Fringilla urna porttitor
      rhoncus dolor. Neque viverra justo nec ultrices dui sapien eget mi.
      Vestibulum sed arcu non odio euismod. At erat pellentesque adipiscing
      commodo elit at imperdiet dui accumsan. Eu non diam phasellus vestibulum
      lorem. Adipiscing elit pellentesque habitant morbi tristique senectus et.
      Dolor sit amet consectetur adipiscing elit ut. Et ligula ullamcorper
      malesuada proin. Amet est placerat in egestas erat imperdiet. Suspendisse
      faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Eros in
      cursus turpis massa tincidunt dui ut ornare. Aenean et tortor at risus
      viverra adipiscing at in. Euismod nisi porta lorem mollis aliquam.
      Fringilla urna porttitor rhoncus dolor purus non enim. Neque egestas
      congue quisque egestas diam in. Pharetra vel turpis nunc eget lorem dolor
      sed. Duis convallis convallis tellus id interdum. Pharetra diam sit amet
      nisl suscipit adipiscing bibendum est ultricies.
    </div>
  );
}
