// Originally forked from https://codesandbox.io/s/r3f-basic-demo-3izwu?file=/src/App.js:0-2775
import { Suspense, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { SceneSpin } from "./SceneSpin";
import useSectionSize from "./useSectionSize";

export default function Section3DSpin() {
  const sectionRef = useRef(null);
  const [y, h] = useSectionSize(sectionRef);
  const { scrollY: _scrollY } = useScroll({ target: sectionRef });
  const scrollY = useTransform(_scrollY, [0, h], [0, 1]);

  const animProgress = useTransform(scrollY, [-0.2, 1.2], [0, 1]);
  const textX1 = useTransform(scrollY, [0, 0.2], ["-40vw", "10vw"]);
  const opacity1 = useTransform(scrollY, [0, 0.2], [0, 1]);
  const textX2 = useTransform(animProgress, [0.2, 0.4], ["-40vw", "10vw"]);
  const opacity2 = useTransform(scrollY, [0.2, 0.4], [0, 1]);
  const textX3 = useTransform(animProgress, [0.4, 0.6], ["-40vw", "10vw"]);
  const opacity3 = useTransform(scrollY, [0.4, 0.6], [0, 1]);

  return (
    <div
      ref={sectionRef}
      className="scroll-snap-section"
      style={{
        position: "relative",
      }}
    >
      <motion.h1
        layout
        children="Contrast."
        style={{
          zIndex: 1,
          whiteSpace: "nowrap",
          position: "absolute",
          top: "10vh",
          left: "10vw",
          fontSize: "4vmin",
          color: "#fff",
          opacity: opacity1,
          x: textX1,
        }}
      />
      <motion.h1
        layout
        children="Text"
        style={{
          zIndex: 1,
          whiteSpace: "nowrap",
          position: "absolute",
          top: "15vh",
          left: "10vw",
          fontSize: "4vmin",
          color: "#fff",
          opacity: opacity2,
          x: textX2,
        }}
      />
      <motion.h1
        layout
        children="Mer text"
        style={{
          zIndex: 1,
          whiteSpace: "nowrap",
          position: "absolute",
          top: "20vh",
          left: "10vw",
          fontSize: "4vmin",
          color: "#fff",
          opacity: opacity3,
          x: textX3,
        }}
      />
      <Suspense fallback={null}>
        <SceneSpin animProgress={animProgress} />
      </Suspense>
    </div>
  );
}
