import React from "react";
import SectionHerringboneTransition from "./SectionHerringboneTransition";
import SectionHerringboneTransition2 from "./SectionHerringboneTransition2";
import SectionHerringboneTransition3 from "./SectionHerringboneTransition3";
import SectionText from "./SectionText";
import SectionVideo from "./SectionVideo";
import SectionDummy from "./SectionDummy";
import Section3DSpin from "./Section3DSpin";
import Section3DClick from "./Section3DClick";

export default function ScrollContainer() {
  return (
    <>
      <SectionDummy />
      <Section3DSpin />
      <SectionDummy />
      {/*<Section3DClick />
      <SectionText />
      <SectionVideo />
      <SectionHerringboneTransition />
      <SectionHerringboneTransition2 />
      <SectionHerringboneTransition3 />
      <SectionText />
  <Section3DClick />*/}
    </>
  );
}
