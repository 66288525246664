import { useLayoutEffect, useState } from "react";

export default function useSectionSize(sectionRef) {
  const [h, setH] = useState(0);
  const [y, setY] = useState(0);
  useLayoutEffect(() => {
    const rect = sectionRef.current?.getBoundingClientRect();
    if (rect?.height > 0) {
      setH(rect.height);
      setY(rect.top);
    }
    console.log("useSectionSize");
  }, [sectionRef]);

  return [y, h];
}
